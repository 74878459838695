import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Container } from '../shared/container';
import { LinkButton } from '../shared/button/button';
import PlanePath from '../../assets/svg/plane-path.svg';
import { Image, GroupQuery } from '../../types';
import { getCurrentLanguageData } from '../../utils/get-current-language-data';

const query = graphql`
  query {
    allPrismicTeam {
      group(field: lang) {
        fieldValue
        nodes {
          data {
            description {
              text
            }
            section_title {
              text
            }
            send_cv_button_label
            team {
              avatar {
                url
                alt
              }
              email
              name
              phone_number
              role
            }
          }
        }
      }
    }
  }
`;

interface TeamData {
  description: {
    text: string;
  };
  section_title: {
    text: string;
  };
  send_cv_button_label: string;
  team: {
    avatar: Image;
    email: string;
    name: string;
    phone_number: string;
    role: string;
  }[];
}

interface TeamQuery {
  allPrismicTeam: GroupQuery<TeamData>;
}

export const Team: React.FC = () => {
  const data = useStaticQuery<TeamQuery>(query);
  const teamData = getCurrentLanguageData(data.allPrismicTeam.group);
  return (
    <Container>
      <section
        id="team"
        className="relative mt-20 md:pt-2 md:mt-28 xl:grid xl:grid-cols-3  lg:gap-x-3"
      >
        <div className="px-px">
          <h2 className="text-3xl leading-14 font-black text-ecit-blue">
            {teamData?.section_title?.text}
          </h2>
          <p className="paragraph-loose text-ecit-blue mt-9 md:mt-14 md:pr-9">
            {teamData?.description?.text}
          </p>
        </div>
        <ul className="md:col-span-2 md:grid md:grid-cols-2 md:gap-x-3 mt-12 xl:mt-0">
          {teamData?.team.map((person, i) => (
            <li className="mb-7 md:mb-7 flex pr-10" key={`team-person-${i}`}>
              <img
                src={person.avatar.url}
                alt={person.avatar.alt}
                className="flex-shrink-0 flex-grow-0 mr-4 h-24"
              />
              <div>
                <h3 className="text-lg leading-7 font-bold text-ecit-blue">
                  {person.name}
                </h3>
                <p className="header-xs my-1 text-greyscale-mediumlight">
                  {person.role}
                </p>
                <a
                  href={`mailto:${person.email}`}
                  className="block paragraph-loose text-ecit-blue"
                >
                  {person.email}
                </a>
                <a
                  href={`tel:${person.phone_number}`}
                  className="block paragraph-loose text-ecit-blue -mt-1.5"
                >
                  {`mob ${person.phone_number}`}
                </a>
              </div>
            </li>
          ))}
          <div>
            <LinkButton
              to="/join-our-team"
              variant="outline"
              width="min"
              className="w-40 mt-12 ml-4"
            >
              {teamData.send_cv_button_label}
            </LinkButton>
          </div>
        </ul>
        <PlanePath className="hidden md:block absolute bottom-0 -left-60 transform rotate-75 -z-10" />
      </section>
    </Container>
  );
};
