import cx from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';

const MAX_HEIGHT_FALLBACK = 99999;

export interface AccordionItemProps {
  item: { title: string | JSX.Element; content: JSX.Element; key: string };
  handleClickItem: any;
  index: number;
  active: boolean;
}

export const AccordionItem: React.FC<AccordionItemProps> = ({
  item,
  handleClickItem,
  index,
  active,
}) => {
  const { content } = item;
  const [maxContentHeight, setMaxContentHeight] = useState(MAX_HEIGHT_FALLBACK);
  const itemRef = useRef<HTMLDivElement>();
  useEffect(() => {
    if (window.ResizeObserver) {
      const resizeObserver = new ResizeObserver(([entry]) => {
        setMaxContentHeight(entry.target.scrollHeight);
      });
      resizeObserver.observe(itemRef.current);
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  const headerClicked = useCallback(() => {
    handleClickItem(item.key);
  }, [item.key]);

  return (
    <li
      className={cx('overflow-hidden mb-7 md:mb-13', {
        'border-greyscale-mediumlight border-b': !active,
        'border-ecit-green border-b-2': active,
      })}
    >
      <div className="-mb-1.75 lg:-mb-4">
        <button
          onClick={headerClicked}
          className="w-full text-left pl-px"
          aria-expanded={active}
          aria-controls={`${item.key}-section`}
          id={`${item.key}-id`}
        >
          <span className="block header-xs text-greyscale-mediumlight pb-4 md:pb-0.5">
            {index + 1}
          </span>
          <span className="block header-xl text-ecit-blue transform transition-transform sm:hover:-translate-y-3">
            {item.title}
          </span>
        </button>
        <div
          ref={itemRef}
          className={cx('transition-all', {
            'max-h-0 overflow-hidden': !active,
          })}
          style={{ maxHeight: active ? `${maxContentHeight}px` : undefined }}
        >
          {content}
        </div>
      </div>
    </li>
  );
};
