import React from 'react';
import { useForm } from 'react-hook-form';
import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { Container } from '../shared/container';
import { Button } from '../shared/button/button';
import { Input } from '../shared/input/input';
import Star from '../../assets/svg/star.svg';
import Rectangle from '../../assets/svg/rectangle.svg';
import { graphql, useStaticQuery } from 'gatsby';
import { Image, GroupQuery } from '../../types';
import { RichText, RichTextBlock } from 'prismic-reactjs';
import { getCurrentLanguageData } from '../../utils/get-current-language-data';

const query = graphql`
  query {
    allPrismicCustomerPanel {
      group(field: lang) {
        fieldValue
        nodes {
          data {
            button_text
            description
            input_placeholder
            number_of_stars
            stars_description {
              raw
            }
            title {
              text
            }
            customer_panel_image {
              alt
              url
            }
            notify_me_success_message
            notify_me_error_message
          }
        }
      }
    }
  }
`;
interface CustomerPanelData {
  button_text: string;
  description: string;
  input_placeholder: string;
  number_of_stars: number;
  stars_description: {
    raw: RichTextBlock[];
  };
  title: {
    text: string;
  };
  customer_panel_image: Image;
  notify_me_success_message: string;
  notify_me_error_message: string;
}

interface CustomerPanelQuery {
  allPrismicCustomerPanel: GroupQuery<CustomerPanelData>;
}

export const CustomerPanel: React.FC = () => {
  const data = useStaticQuery<CustomerPanelQuery>(query);
  const customerPanelData = getCurrentLanguageData(
    data.allPrismicCustomerPanel.group
  );
  const { register, handleSubmit } = useForm();
  const onSubmit = data => {
    axios
      .post(`${process.env.GATSBY_API_URL}/customerPanelForm`, data)
      .then(() => {
        toast.success(customerPanelData.notify_me_success_message);
      })
      .catch(({ response }: { response: AxiosResponse }) => {
        toast.error(customerPanelData.notify_me_error_message);
      });
  };

  return (
    <Container>
      <section
        id="customer_panel"
        className="mt-16 md:mt-44 flex flex-col-reverse md:flex-row "
      >
        <div className="max-w-4xl flex-shrink-0 mt-2.5 md:mt-20 md:ml-0.5">
          <div className="p-2 mt-1.5 rounded-lg shadow-rectangle bg-ecit-green inline-block">
            <Rectangle />
          </div>
          <h2 className="header-m text-ecit-blue mt-2">
            {customerPanelData.title.text}
          </h2>
          <p className="paragraph text-greyscale-mediumlight mt-4 mb-5 pr-5">
            {customerPanelData.description}
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Input
              placeholder="Enter your email"
              className="w-full md:w-80"
              {...register('email', { required: true })}
            />
            <Button
              className="mt-5 mb-10 w-full md:w-80"
              width="fixed"
              variant="secondary"
            >
              {customerPanelData.button_text}
            </Button>
          </form>
          {/* <div className="flex items-center">
            {[...Array(customerPanelData.number_of_stars)].map((_, i) => (
              <Star key={`star-${i}`} />
            ))}
            <span className="paragraph-sm text-greyscale-mediumlight ml-2.5">
              <RichText render={customerPanelData.stars_description.raw} />
            </span>
          </div> */}
        </div>
        <div className="flex-shrink-0 ml-22 md:ml-28 min-w-full">
          {customerPanelData.customer_panel_image && (
            <img
              src={customerPanelData.customer_panel_image.url}
              alt={customerPanelData.customer_panel_image.alt}
              className="shadow-image rounded-lg"
            />
          )}
        </div>
      </section>
    </Container>
  );
};
