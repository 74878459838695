import React from 'react';
import { Container } from '../shared/container';
import { LastArticles } from '../shared/last-articles';
import { graphql, useStaticQuery } from 'gatsby';
import { GroupQuery } from '../../types';
import { getCurrentLanguageData } from '../../utils/get-current-language-data';

const query = graphql`
  query {
    allPrismicBlog {
      group(field: lang) {
        fieldValue
        nodes {
          data {
            small_title
            big_title
          }
        }
      }
    }
  }
`;
interface BlogData {
  small_title: string;
  big_title: string;
}

interface BlogQuery {
  allPrismicBlog: GroupQuery<BlogData>;
}

export const Blog: React.FC = () => {
  const data = useStaticQuery<BlogQuery>(query);
  const texts = getCurrentLanguageData(data.allPrismicBlog.group);
  return (
    <section className="mt-28">
      <h2>
        <div className="max-w-body m-auto mb-6">
          <span className="header-2xl text-ecit-green">{texts.big_title}</span>
        </div>
      </h2>
      <Container>
        <LastArticles />
      </Container>
    </section>
  );
};
