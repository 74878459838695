import React from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/shared/seo';
import { Header } from '../../components/shared/header/header';
import { Hero } from '../../components/home/hero';
import { CustomerPanel } from '../../components/home/customer-panel';
import { Services } from '../../components/home/services';
import { AboutUs } from '../../components/home/about-us';
import { Team } from '../../components/home/team';
import { Blog } from '../../components/home/blog';
import { WeHelp } from '../../components/home/we-help';
import { Footer } from '../../components/shared/footer/footer';
import { ToastContainer } from 'react-toastify';

const IndexPage: React.FC<{}> = () => {
  return (
    <Layout>
      <SEO title="Index" />
      <Header />
      <Hero />
      <CustomerPanel />
      <Services />
      <AboutUs />
      <Team />
      <Blog />
      <WeHelp />
      <Footer />
      <ToastContainer position="top-right" autoClose={5000} />
    </Layout>
  );
};

export default IndexPage;
