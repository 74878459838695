import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Container } from '../shared/container';
import { LinkButton } from '../shared/button/button';
import Plane from '../../assets/svg/plane.svg';
import PlanePath from '../../assets/svg/plane-path.svg';
import { GroupQuery } from '../../types';
import { getCurrentLanguageData } from '../../utils/get-current-language-data';

const query = graphql`
  query {
    allPrismicHero {
      group(field: lang) {
        fieldValue
        nodes {
          data {
            big_title
            button_text
            medium_title
            small_title
            text
          }
        }
      }
    }
  }
`;

interface HeroData {
  big_title: string;
  button_text: string;
  medium_title: string;
  small_title: string;
  text: string;
}

interface HeroQuery {
  allPrismicHero: GroupQuery<HeroData>;
}

export const Hero: React.FC = () => {
  const data = useStaticQuery<HeroQuery>(query);
  const heroData = getCurrentLanguageData(data.allPrismicHero.group);

  return (
    <section className="mt-36 sm:mt-90">
      <h1>
        <div className="block max-w-body m-auto">
          <span className="header-2xl sm:header-3xl -mt-1">
            {heroData.big_title}
          </span>
        </div>
        <Container>
          <span className="text-2xl leading-none sm:leading-14 font-black mt-8 sm:-mt-1">
            {heroData.medium_title}
          </span>
        </Container>
      </h1>
      <Container>
        <p className="paragraph max-w-xl sm:pr-10 mb-7 sm:mb-9">
          {heroData.text}
        </p>
        <div className="relative">
          <LinkButton className="z-10" variant="outline" to="/contact">
            {heroData.button_text}
          </LinkButton>
          <Plane className="absolute -top-5 left-2.5" />
          <PlanePath className="absolute -left-36 top-2.5 -z-10" />
        </div>
      </Container>
    </section>
  );
};
