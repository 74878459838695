import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Container } from '../shared/container';
import { Image, GroupQuery } from '../../types';
import { RichText, RichTextBlock, Elements } from 'prismic-reactjs';
import { propsWithUniqueKey } from '../../utils/html-serializer';
import linkResolver from '../../utils/link-resolver';
import { getCurrentLanguageData } from '../../utils/get-current-language-data';

const query = graphql`
  query {
    allPrismicAboutUs {
      group(field: lang) {
        fieldValue
        nodes {
          data {
            big_title {
              text
            }
            small_title {
              text
            }
            background_image {
              alt
              url
              dimensions {
                height
                width
              }
            }
            column_1 {
              raw
            }
            column_2 {
              raw
            }
            ecit_logo {
              alt
              url
            }
            section_title {
              text
            }
            we_are_part_of_text
          }
        }
      }
    }
  }
`;

interface AboutUsData {
  big_title: {
    text: string;
  };
  small_title: {
    text: string;
  };
  background_image: Image;
  column_1: {
    raw: RichTextBlock[];
  };
  column_2: {
    raw: RichTextBlock[];
  };
  ecit_logo: Image;
  section_title: {
    text: string;
  };
  we_are_part_of_text: string;
}

interface AboutUsQuery {
  allPrismicAboutUs: GroupQuery<AboutUsData>;
}

const htmlSerializer = function (type, element, content, children, key) {
  let props = {};
  switch (type) {
    case Elements.paragraph:
      props = {
        className: 'min-h-8',
        ...(element.direction && { dir: element.direction }),
      };
      return React.createElement('p', propsWithUniqueKey(props, key), children);
    case Elements.hyperlink: // Link
      const targetAttr = element.data.target
        ? { target: element.data.target }
        : {};
      const relAttr = element.data.target ? { rel: 'noopener' } : {};
      props = Object.assign(
        {
          href: element.data?.url || linkResolver(element.data),
          className: 'underline',
        },
        targetAttr,
        relAttr
      );
      return React.createElement('a', propsWithUniqueKey(props, key), children);
    default:
      return null;
  }
};

export const AboutUs: React.FC = () => {
  const data = useStaticQuery<AboutUsQuery>(query);
  const aboutUsData = getCurrentLanguageData(data.allPrismicAboutUs.group);

  return (
    <section id="about_us" className="relative bg-ecit-blue overflow-y-hidden">
      <div className="absolute w-full top-0 max-h-full 2xl:bottom-0">
        {aboutUsData.background_image && (
          <img
            className="w-full object-cover 2xl:absolute 2xl:top-1/2 transform 2xl:-translate-y-1/2"
            src={aboutUsData.background_image.url}
            alt={aboutUsData.background_image.alt}
          />
        )}
        <div className="absolute bottom-0 h-1/3 sm:h-1/2 w-full bg-gradient-to-b from-transparent to-ecit-blue"></div>
      </div>
      <div className="relative z-10 pt-40 sm:pt-96 pb-7 sm:pb-40">
        <h2>
          <div className="max-w-body m-auto">
            <span className="header-2xl whitespace-nowrap text-white">
              {aboutUsData.big_title.text}
            </span>
          </div>
        </h2>
        <Container>
          <div className="grid sm:grid-flow-col sm:grid-cols-2 md:auto-cols-min gap-x-5 lg:gap-x-32 mt-3 paragraph-loose text-white">
            <div className="md:w-80 sm:pr-3">
              <h3 className="header-xs text-greyscale-mediumlight ">
                {aboutUsData.section_title.text}
              </h3>
              <RichText
                render={aboutUsData.column_1.raw}
                htmlSerializer={htmlSerializer}
              />
            </div>
            <div className="md:w-80 pt-4">
              <RichText
                render={aboutUsData.column_2.raw}
                htmlSerializer={htmlSerializer}
              />
              <div className="flex items-baseline text-white mt-6">
                <span className="paragraph-loose text-ecit-green font-bold mr-5">
                  {aboutUsData.we_are_part_of_text}
                </span>
                {aboutUsData.ecit_logo && (
                  <img
                    src={aboutUsData.ecit_logo.url}
                    alt={aboutUsData.ecit_logo.alt}
                  />
                )}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </section>
  );
};
