import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { LinkButton } from './button/button';
import { Link } from '../shared/link';
import { Article } from '../article/article';
import { ArticlePreview, GroupQuery } from '../../types';
import {
  getCurrentLanguageData,
  getCurrentLanguage,
} from '../../utils/get-current-language-data';

export interface LastArticlesProps {
  currentArticleId?: string;
}

interface BlogData {
  see_all_articles_link_text: string;
}

interface LastArticlesQuery {
  allPrismicArticle: {
    group: {
      fieldValue: string;
      nodes: ArticlePreview[];
    }[];
  };
  allPrismicBlog: GroupQuery<BlogData>;
}

const query = graphql`
  query LastArticles {
    allPrismicArticle(sort: { fields: first_publication_date, order: DESC }) {
      group(field: lang, limit: 4) {
        fieldValue
        nodes {
          data {
            author_photo {
              alt
              url
            }
            author_name
            author_role
            preview {
              raw
            }
            title {
              text
            }
          }
          first_publication_date(formatString: "DD / MM / yyyy")
          uid
          id
        }
      }
    }
    allPrismicBlog {
      group(field: lang) {
        fieldValue
        nodes {
          data {
            see_all_articles_link_text
          }
        }
      }
    }
  }
`;

export const LastArticles: React.FC<LastArticlesProps> = ({
  currentArticleId,
}) => {
  const data = useStaticQuery<LastArticlesQuery>(query);
  const lang = getCurrentLanguage();
  const lastArticles = data.allPrismicArticle.group
    .find(group => group.fieldValue === lang)
    .nodes.filter(article => article.id !== currentArticleId)
    .slice(0, 3);
  const text = getCurrentLanguageData(data.allPrismicBlog.group);
  return (
    <div>
      <LinkButton
        variant="outline"
        width="min"
        to="/blog"
        className="mt-1 w-full md:w-80 mb-11 md:mb-0"
      >
        {text.see_all_articles_link_text}
      </LinkButton>
      <ul className="md:grid md:grid-cols-2">
        {lastArticles.map(article => (
          <li className="md:pr-8 mb-14 md:mb-0 md:mt-10" key={article.id}>
            <Link to={`/${article.uid}`} className="block mb-16 pb-1">
              <Article
                date={article.first_publication_date}
                title={article.data.title.text}
                author={{
                  name: article.data.author_name,
                  role: article.data.author_role,
                  image: article.data.author_photo.url,
                }}
                content={
                  article.data.preview?.raw?.length
                    ? [
                        {
                          type: 'content',
                          content: article.data.preview.raw,
                        },
                      ]
                    : []
                }
              />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
