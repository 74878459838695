import React from 'react';
import { ArticleContent, ArticleContentProps } from './article-content';
import { Date } from './date';
import { Author, AuthorProps } from './author';

export interface ArticeProps extends ArticleContentProps {
  date: string;
  title: string;
  author: AuthorProps;
}

export const Article: React.FC<ArticeProps> = ({
  date,
  title,
  author,
  content,
}) => (
  <article>
    <Date>{date}</Date>
    <h2 className="header-xl-bold text-ecit-blue mt-4 sm:mt-5">{title}</h2>
    <ArticleContent content={content} />
    <Author {...author} />
  </article>
);
