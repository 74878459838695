import React, { useState } from 'react';
import { AccordionItem } from './accordion-item';

export interface AccordionProps {
  items: { title: string | JSX.Element; content: JSX.Element; key: string }[];
  className?: string;
}

export const Accordion: React.FC<AccordionProps> = ({ items, className }) => {
  const [openedSection, setOpenedSection] = useState<string>();

  const handleClickItem = (itemKey: string) => {
    openedSection === itemKey
      ? setOpenedSection(null)
      : setOpenedSection(itemKey);
  };

  return (
    <ul className={className}>
      {items.map((item, index) => (
        <AccordionItem
          key={item.key}
          active={openedSection === item.key}
          item={item}
          index={index}
          handleClickItem={handleClickItem}
        />
      ))}
    </ul>
  );
};
