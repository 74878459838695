import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Container } from '../shared/container';
import { Accordion } from '../shared/accordion/accordion';
import { LinkButton } from '../shared/button/button';
import { RichText, RichTextBlock } from 'prismic-reactjs';
import { htmlSerializer } from '../../utils/html-serializer';
import { Image, GroupQuery } from '../../types';
import { getCurrentLanguageData } from '../../utils/get-current-language-data';

const query = graphql`
  query {
    allPrismicServices {
      group(field: lang) {
        fieldValue
        nodes {
          data {
            body {
              ... on PrismicServicesBodyServices {
                id
                items {
                  avatar {
                    alt
                    url
                  }
                  foreign_desk
                }
                primary {
                  column_1 {
                    raw
                  }
                  column_2 {
                    raw
                  }
                  column_3 {
                    raw
                  }
                  contact_link_button_text
                  subtitle1 {
                    text
                  }
                  title1 {
                    text
                  }
                }
              }
            }
            subtitle {
              text
            }
            title {
              text
            }
            foreign_desk_text
          }
        }
      }
    }
  }
`;

interface ServiceData {
  body: {
    id: string;
    items: {
      avatar: Image;
      foreign_desk: boolean;
    }[];
    primary: {
      column_1: {
        raw: RichTextBlock[];
      };
      column_2: {
        raw: RichTextBlock[];
      };
      column_3: {
        raw: RichTextBlock[];
      };
      contact_link_button_text: string;
      subtitle1: {
        text: string;
      };
      title1: {
        text: string;
      };
    };
  }[];
  subtitle: {
    text: string;
  };
  title: {
    text: string;
  };
  foreign_desk_text: string;
}

interface ServicesQuery {
  allPrismicServices: GroupQuery<ServiceData>;
}

export const Services: React.FC = () => {
  const data = useStaticQuery<ServicesQuery>(query);
  const services = getCurrentLanguageData(data.allPrismicServices.group);

  return (
    <section id="services" className="mt-32 md:mt-40 mb-32">
      <div className="max-w-body m-auto">
        <h2 className="header-2xl whitespace-nowrap text-ecit-blue">
          {services.title.text}
        </h2>
      </div>

      <Container>
        <h3 className="header-m-bold text-ecit-blue -mt-4 md:-mt-3 md:pb-3 mb-6 md:mb-16">
          {services.subtitle.text}
        </h3>
        <Accordion
          items={services.body.map((section, i) => {
            return {
              title: section.primary.title1.text,
              key: section.primary.title1.text,
              content: (
                <>
                  <h4 className="header-l text-ecit-green mt-9 ">
                    {section.primary.subtitle1.text}
                  </h4>
                  <ul className="grid md:grid-cols-3 md:gap-x-6 mt-10 md:mt-8 text-ecit-blue paragraph-loose">
                    <li key={`service-${i}-column-1`}>
                      <RichText
                        render={section.primary.column_1.raw}
                        htmlSerializer={htmlSerializer}
                      />
                    </li>
                    <li key={`service-${i}-column-2`}>
                      <RichText
                        render={section.primary.column_2.raw}
                        htmlSerializer={htmlSerializer}
                      />
                    </li>
                    <li key={`service-${i}-column-3`}>
                      <RichText
                        render={section.primary.column_3.raw}
                        htmlSerializer={htmlSerializer}
                      />
                    </li>
                  </ul>
                  <div className="flex flex-col-reverse mr-2 md:items-end mt-10 mb-12 md:pt-1 md:mt-22 md:mb-32 md:flex-row md:justify-between">
                    <LinkButton
                      to="/contact"
                      className="flex-grow-0 w-full md:w-80 mt-2 mb-12 md:mt-0 md:mb-0"
                      width="min"
                    >
                      {section.primary.contact_link_button_text}
                    </LinkButton>
                    {section.items.length > 0 && (
                      <div className="flex items-end md:justify-end flex-wrap gap-2 md:gap-4 md:max-w-sm">
                        {section.items
                          .filter(p => !p.foreign_desk)
                          .map((person, index) => (
                            <img
                              key={person.avatar.alt || index}
                              src={person.avatar.url}
                              alt={person.avatar.alt}
                              className="flex-shrink-0 flex-grow-0 h-12 w-12 xl:w-20 xl:h-20 object-contain"
                            />
                          ))}
                        {section.items.filter(p => p.foreign_desk).length >
                          0 && (
                          <div className="border-l border-greyscale-light pl-1 md:pl-2 -ml-1 md:-ml-2">
                            <span className="header-xs text-greyscale-mediumlight">
                              {services.foreign_desk_text}
                            </span>
                            <div className="flex gap-2 md:gap-4">
                              {section.items
                                .filter(p => p.foreign_desk)
                                .map((person, index) => (
                                  <img
                                    key={person.avatar.alt || index}
                                    src={person.avatar.url}
                                    alt={person.avatar.alt}
                                    className="flex-shrink-0 flex-grow-0 h-12 w-12 xl:w-20 xl:h-20 object-contain"
                                  />
                                ))}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </>
              ),
            };
          })}
        />
      </Container>
    </section>
  );
};
