import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Container } from '../shared/container';
import { GroupQuery } from '../../types';
import { getCurrentLanguageData } from '../../utils/get-current-language-data';

const query = graphql`
  query {
    allPrismicWeHelp {
      group(field: lang) {
        fieldValue
        nodes {
          data {
            big_title
            description
            highlighted_text
            small_title
          }
        }
      }
    }
  }
`;

interface WeHelpData {
  big_title: string;
  description: string;
  highlighted_text: string;
  small_title: string;
}

interface WeHelpQuery {
  allPrismicWeHelp: GroupQuery<WeHelpData>;
}

export const WeHelp: React.FC = () => {
  const data = useStaticQuery<WeHelpQuery>(query);
  const texts = getCurrentLanguageData(data.allPrismicWeHelp.group);

  return (
    <section
      id="we_help"
      className="relative z-10 mt-36 sm:mt-60 sm:pt-6 mb-36"
    >
      <h2>
        <div className="max-w-body m-auto">
          <span className="header-2xl whitespace-nowrap sm:text-ecit-blue">
            {texts.big_title}
          </span>
        </div>
        <Container>
          <span className="header-l-bold sm:text-ecit-blue sm:mt-3.5 sm:p-1">
            {texts.small_title}
          </span>
        </Container>
      </h2>
      <Container>
        <div className="sm:p-1">
          <p className="text-lightBlue paragraph-xl max-w-4xl -mt-2 sm:mt-4">
            {texts.description}{' '}
            <span className="text-greyscale-dark">
              {texts.highlighted_text}
            </span>
          </p>
        </div>
      </Container>
    </section>
  );
};
