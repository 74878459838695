import React from 'react';

export interface AuthorProps {
  name: string;
  role: string;
  image: string;
}

export const Author: React.FC<AuthorProps> = ({ name, role, image }) => {
  return (
    <div className="flex items-center my-2">
      <img
        src={image}
        alt={name}
        className="flex-shrink-0 flex-grow-0 mr-2.5 h-12 w-12"
      />
      <div>
        <p className="paragraph text-ecit-blue font-black">{name}</p>
        <p className="header-xs text-greyscale-mediumlight">{role}</p>
      </div>
    </div>
  );
};
